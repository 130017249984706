<template>
  <v-app>
    <v-banner
        class="lavBanner" 
        single-line
        height="400"
    >
      <v-img
          src="https://h5.ophyer.cn/official_website/banner/IPSolution-banner.png"
          height="100%"
          class="d-flex align-center"
        >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              size="80"
              color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-container color="transparent" class="d-flex justify-center">
          <v-card color="transparent" flat width="80%">
            <div class="d-flex flex-no-wrap justify-center" style="color: #fff">
              <div>
                <v-card-title class="white--text font-size-banner-title font-weight-bold px-0">
                  汇聚全领域IP，实现精准对接
                </v-card-title>
                <v-card-title class="white--text font-size-banner-tip px-0" style="line-height:30px;margin:0 auto;text-align: center;display: block;">
                  汇聚全领域优质资源，解决授权行业信息不对称的问题
                </v-card-title>
              </div>
            </div>
          </v-card>
        </v-container>
      </v-img>
    </v-banner>
    <!-- 授权模式，应有尽有 -->
    <div class="first">
      <div class="public-title">
          <p>AUTHORIZATION MODEL</p>
          <div>
            <span>授权模式，应有尽有</span>
            <span>多种授权模式，满足所有的运营需求</span>
          </div>
        </div>
      <div class="box mt-12">
        <v-container>
          <v-row no-gutters justify="center" >
            <v-col v-for="(n, ind) in typeList" :key="ind" col='3' style="width:1200px;">
              <div class="img_title" justify="center">
                <v-img :src="n.img" />
                <h2>{{n.title}}</h2>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <!-- 核心技术应用 -->
    <v-container>
      <div class="public-title" style="margin:17px 0 35px 0">
        <p>APPLICATION</p>
        <div>
          <span>核心技术应用</span>
          <span>由新技术引领，呈现生态化运营、产业化发展的新动向</span>
        </div>
      </div>
      <v-container class="d-flex justify-center">
        <v-row class="max-width-1200" style="width:1200px;">
          <v-col cols="3" v-for="(card,index) in application" :key="index">
            <v-card elevation="0">
              <div class="d-flex justify-center">
                <v-img
                      :src="card.img"
                      class="white--text align-end flex-grow-0 rounded-lg"
                      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                      height="160"
                      width="240">
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          size="80"
                          color="red lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                </v-img>
              </div>
          <div class="d-flex justify-center">
            <v-card-text class="justify-center mt-2 font-size-14 " style="width:240px;color:#666" v-html="card.content">{{ card.content }}</v-card-text>
          </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-container>

     <!-- 合作案例 -->
    <!--<v-container>-->
    <!--  <div class="d-flex justify-center align-center mb-9 case_card" style="width:100%;height:100%">-->
    <!--    <div style="width:100%">-->
    <!--      <div class="public-title">-->
    <!--        <p>COOPERATION CASE</p>-->
    <!--        <div>-->
    <!--          <span >合作案例</span>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <v-container style="max-width:1120px;">-->
    <!--        <v-row>-->
    <!--            <v-col v-for="(card, index) in anli" :key="index" cols="3">-->
    <!--                <v-card-->
    <!--                  class="mx-auto item-card"-->
    <!--                  max-width="240"-->
    <!--                  max-height="410"-->
    <!--                  color="transparent"-->
    <!--                  @click="goCaseDetail(card)"-->
    <!--                >-->
    <!--                  <v-img-->
    <!--                    max-height="340"-->
    <!--                    :src="card.coverImg"-->
    <!--                    :aspect-ratio="9/16"-->
    <!--                  >-->
    <!--                  <template v-slot:placeholder>-->
    <!--                          <v-row class="fill-height ma-0" align="center" justify="center">-->
    <!--                            <v-progress-circular-->
    <!--                              indeterminate-->
    <!--                              size="80"-->
    <!--                              color="red lighten-5"-->
    <!--                            ></v-progress-circular>-->
    <!--                          </v-row>-->
    <!--                  </template>-->
    <!--                  </v-img>-->
    <!--                  <v-card-text class="font-size-14 font-weight-bold" v-text="card.itemTitle"></v-card-text>-->
    <!--                </v-card>-->
    <!--            </v-col>-->
    <!--        </v-row>-->
    <!--      </v-container>-->
    <!--    </div>-->
    <!--  </div>-->
    <!--</v-container>-->

  </v-app>
</template>

<script>
import qs from 'qs'
import FanDate from '../../static/FanData.js'
import textData from '@/static/textData.js' // 合作案例
export default {
  name: "ServiceContent",
  data() {
    return {
     typeList:FanDate.IP.typeList,
     bannerLoad: false,
     imgLoad: false,
     application:FanDate.IP.application,
     anli:[textData.anli.meilizhongguo,textData.anli.hongcunjiyi,textData.anli.qinghuaxiaoyuan,textData.anli.bowuguan],
    }
  },
  mounted(){
    // var _this = this;
    this.bannerLoad = true;

  },
  methods: {
    handleLoad(){
      this.imgLoad = true;
    },
    goCaseDetail:(item) =>{
      let query = {
        source: 'qita',
        anli: item.self
      }
      let url = '/CaseDetail?'+qs.stringify(query)
      window.open(url, '_blank')
    }
  }
}
</script>

<style scoped lang='scss'>
.lavBanner {
  ::v-deep .v-banner__wrapper,
  ::v-deep .v-banner__content,
  ::v-deep .v-banner__text {
    padding: 0 !important;
    height: 100%;
  }
}
.first {
   width: 1200px;
   margin: 0 auto;
  .box {
    margin: 0px auto;
    text-align: center;
    justify-content: center;
    //border-bottom: 1px solid;
    h3 {
      margin-bottom: 40px;
      font-size: 40px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333333;
      line-height: 44px;
    }
    p {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #666;
      line-height: 20px;
      margin-top: 20px;
    }
    h2 {
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 40px;
      margin-bottom: 40px;
    }
    .theme--light.v-image {
      width: 100px;
      margin: 0 auto;
    }
    .img_title {
      width: 251px;
      margin: 0 auto;
      h2 {
        font-size: 20px;
        color: #333;
        // margin-top: 10px;
      }
      p {
        font-size: 14px;
        margin-top: 10px;
        padding: 0 30px;
        // padding: 0px 45px
      }
    }
  }
}
.item-card {
  margin-bottom: 15px;
  padding: 10px;
  box-shadow: 0px 10px 20px 0px rgba(0, 60, 255, 0.1);
  border-radius: 8px;
  &::after {
      content: "";
      background-image: url("./../../assets/images/home/case-icon.png");
      background-size: 100%;
      width: 20px;
      height: 20px;
      position: absolute;
      bottom: 16px;
      right: 10px;
  }
  transition: 0.3s;
  &:hover {
      transform: scale(1.04);
      box-shadow: 0px 10px 20px 0px rgba(0, 60, 255, 0.1) !important;
  }
  &:focus{
    display: none;
  }
}
::v-deep .case_card{
  .public-title{
    margin-top: 20px;
  }
}
</style>